import { FC, useEffect, useState, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { ToastType, useToasts } from '../../../../contexts/ToastContext';
import { isUrlValid } from '../../../../utils/UrlUtils';
import { Input } from '../../../shared/form-control/Input';
import CopyIcon from '../../../shared/icon/CopyIcon';
import LinkIcon from '../../../shared/icon/LinkIcon';
import Tooltip from '../../../shared/Tooltip';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';

const LinkAction: FC<ActionBaseProps<string>> = (props) => {
  const { t } = useTranslation('common');
  const { response, required, data } = props;
  const { onAnswer, onValid, readOnly } = useFormAction(props);
  const [linkUrl, setLinkUrl] = useState<string>(response || '');
  const [invalidUrl, setInvalidUrl] = useState<string | undefined>();
  const [tooltipText, setTooltipText] = useState<string>(t('copy-text.tooltip'));
  const toasts = useToasts();

  useEffect(() => {
    if (linkUrl.length > 0) {
      setInvalidUrl(!isUrlValid(linkUrl, true) ? t('file-upload.errors.invalid-url') : undefined);
    }
  }, [linkUrl, t]);

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    onValid(!invalidUrl);
    if (!invalidUrl) {
      onAnswer(e.target.value);
    }
  };

  const copyLink = () => {
    navigator.clipboard.writeText(linkUrl);
    setTooltipText(t('copy-text.success'));
    toasts.addToast({
      type: ToastType.INFO,
      title: t('copy-text.success'),
      expiresInMs: 2000,
    });
    setTimeout(() => {
      setTooltipText(t('copy-text.tooltip'));
    }, 2000);
  };

  return (
    <div data-cy="link-action">
      <ActionTitleDescription required={required} {...data} />
      <div className="w-[300px]">
        <Input
          placeholder={t('link-modal.url-input.placeholder')}
          value={linkUrl}
          error={linkUrl && invalidUrl}
          disabled={readOnly}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onBlur={onBlur}
        >
          <Input.Slot name="leading">{<LinkIcon className="ml-2 h-4 w-4" />}</Input.Slot>
          <Input.Slot name="trailing">
            {
              <Tooltip text={tooltipText}>
                {(tooltip) => (
                  <div {...tooltip} className="relative cursor-pointer" onClick={copyLink}>
                    <CopyIcon className="mr-2 h-5 w-5" />
                  </div>
                )}
              </Tooltip>
            }
          </Input.Slot>
        </Input>
      </div>
    </div>
  );
};

export default LinkAction;
